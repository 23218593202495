html {
  background: #2e383f;
}
*,
#root {
  box-sizing: border-box;
  max-width: 100vw;
  overflow: scroll;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  padding: 20px;
  color: #fefefe;
}
.scanner {
  width: 400px;
  height: 300px;
  background-color: #f5f4ed;
  display: flex;
  justify-content: center;
  align-items: center;
}
video {
  object-fit: cover !important;
  overflow-clip-margin: content-box;
  overflow: clip;
}
#frameTabs {
  transform: scale(0.95);
  transform-origin: center;
}
.pause-button {
  position: absolute;
  z-index: 1;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.output {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
}
ul {
  width: 100%;
  padding: 0;
  margin: 2px 0;
}
.article-list li {
  font-size: 12px;
  color: #fefefe;
  list-style: auto;
  padding: 4px 10px;
  border-bottom: thin dotted #fefefe50;
  list-style-position: inside;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article-list li .selectedItem {
  color: #41d6e1;
  border: thin solid #41d6e1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  background-color: #41d6e110;
}
.article-list li .selectedItem .active {
  color: #41d6e1;
  border: thin solid;
  height: 23px;
  border-radius: 5px;
  background: #fefefe20;
}
.note-list li {
  font-size: 12px;
  color: #fefefe;
  list-style: auto;
  padding: 4px 10px;
  border-bottom: thin dotted #fefefe50;
  list-style-position: inside;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.selectedItem {
  color: #41d6e1;
  border: thin solid #41d6e1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 3px;
  background-color: #41d6e110;
}
.selectedItem .active {
  color: #41d6e1;
  border: thin solid;
  height: 23px;
  border-radius: 5px;
  background: #fefefe20;
}
.note-list li .list-text {
}
.note-list li .article-note {
  font-style: italic;
  margin: 0 7px;
  display: flex;
  align-items: center;
}
.article-note svg {
  color: #dbdf91;
}
.article-note.alert {
  background: #ff000070;
  border-radius: 10px;
  padding: 0 20px;
  border: thin solid red;
  width: max-content;
}
.note-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
}
.notification-box {
  display: flex;
  margin-bottom: 6px;
}

.article-note.notification {
  background: #dbdf9170;
  border-radius: 10px;
  padding: 0 20px;
  border: thin solid #dbdf91;
  width: max-content;
}
.company-italic {
  font-size: 12px;
  color: #41d6e150;
}
button {
  height: 40px;
  padding: 10px 20px;
  border: thin solid #fefefe;
  color: #fefefe;
  width: 99%;
  margin: 1px;
  background-color: transparent;
  border-radius: 7px;
  margin-bottom: 22px;
}

.mid-text-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  overflow: hidden;
  width: 100%;
  color: #41d6e1;
  border-bottom: thin solid #41d6e1;
  text-transform: uppercase;
  max-width: 400px;
  padding: 0 0 0 10px;
}
.clearList {
  color: #41d6e1;
  display: flex;
  padding: 10px;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
}
.mid-text-area h4 {
  margin: 0;
  padding: 0;
}

.date {
  color: #41d6e160;
  width: 90%;
  height: 8px;
  text-align: right;
  font-size: 16px;
  margin-bottom: 10px;
}
.whoInput {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 16px;
  outline: none;
  background: none;
  border: thin solid #41d6e150;
  border-bottom: 2px solid #41d6e1;
  color: #41d6e1;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
}
.emailButton {
  color: #2e383f;
  font-weight: bolder;
  height: 40px;
  width: 90%;
  border: thin solid #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 3px;
  background: #41d6e1;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.bottom-button-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 20px;
  align-items: center;
}
.modal-shade {
  background-color: #00000050;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: #2e383fe9;
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  width: 400px;
  height: 96vh;
  flex-direction: column;
  z-index: 2;
  padding: 55px 10px;
}
.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.modal-header h4 {
  margin: 0;
}
.modal-header h5 {
  margin: 0;
}
.modal-header h1 {
  margin: 0;
}
.note-textarea {
  background-color: #2e383f;
  border: thin solid beige;
  resize: none;
  height: 120px;
  border-radius: 4px;
  color: #fefefe;
  padding: 10px;
  font-size: 18px;
  margin: 0;
  width: 100%;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #2e383f;
  color: #fefefe;
  border-radius: 0px 0px 0px 16px;
  padding: 9px 20px;
  cursor: pointer;
}
.nav-bar {
  background: #2e383f;
  z-index: 10;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 33px;
  bottom: 0px;
  left: 0;
  right: 0;
  border-top: thin solid #fefefe50;
  transition: height ease-in-out 200ms;
}
.nav-bar.open {
  height: 250px;
}
.nav-bar .button-group {
  position: absolute;
  top: 0;
  height: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  text-transform: uppercase;
  font-size: 14px;
}
.nav-bar .button-group a,
.hidden-content-list a {
  border: thin solid #41d6e150;
  padding: 2px 5px;
  border-radius: 2px;
  background: #41d6e110;
  transition: all ease-in-out 200ms;
}
.nav-bar .button-group a.active,
.hidden-content-list a.active {
  border: thin solid #fefefe50;
  padding: 2px 5px;
  border-radius: 2px;
  background: #fefefe10;
  color: #fefefe;
}
.nav-bar-hidden-content {
  background-color: #283137;
  width: 100%;
  height: 210px;
  position: absolute;
  top: 30px;
  padding: 10px;
  color: #fefefe;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.hidden-content-list {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.nav-bar a {
  color: #41d6e1;
  text-decoration: none;
}
.setting-button {
  position: absolute;
  top: 5px;
  right: 13px;
  color: #41d6e1;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.setting-header {
  display: flex;
  gap: 10px;
  color: #fefefe70;
  justify-content: center;
}
.setting-header svg {
  width: 20px;
  height: 20px;
}
.setting-button svg {
  width: 20px;
  height: 20px;
}
/* LANDING CONTENT */
.landing-content {
  color: #fefefe;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
/* COMPLETE */

.complete-content {
  color: #fefefe;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
}
.complete-content h1 {
  color: #fefefe50;
}
.notes-window {
  background: #283137;
  width: 100%;
  min-height: 50px;
  padding: 20px 10px;
  border-radius: 3px;
  border: thin solid #fefefe20;
}
.notes {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.notes-text {
  width: 290px;
}
.notes-button {
  display: flex;
  width: 40px;
  height: 40px;
  border: thin solid #41d6e1;
  color: #41d6e1;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  background-color: #41d6e130;
}
.notes-button-group {
  width: 50px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}
/* CHECKBOX */

.checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 96%;
  padding: 10px;
  border: thin solid #fefefe;
  border-radius: 7px;
}
.checkbox-wrapper table {
  width: 95%;
}
.checkbox-wrapper .table-checkbox {
  width: 60px;
}
.checkbox-wrapper .table-checkbox-label {
  font-size: 25px;
}

.checkbox-wrapper input[type="checkbox"] {
  width: 55px;
  height: 55px;
  border-radius: 10px;
}

/* Router */
.router-link {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.router-link a {
  border: thin solid #fefefe;
  padding: 10px 20px;
  color: #fefefe;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.link-row {
  display: flex;
  width: 100%;
  gap: 8px;
}

/* Stored Page */
.stored-group {
  border: thin solid white;
  border-radius: 7px;
  padding: 6px;
  margin: 4px 0px;
}
.stored-group h3 {
  color: #41d6e1;
}
.restore-button-frame {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.button-small {
  height: 28px;
  padding: 5px 10px;
  border-radius: 3px;
  width: fit-content;
  text-transform: uppercase;
  font-size: 10px;
  margin: 5px 2px 2px 0px;
  border-color: #41d6e1;
  color: #41d6e1;
  background-color: #41d6e130;
}

/* MCS page */
.mcs-info {
  display: flex;
  flex-direction: column;
}
